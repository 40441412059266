
interface PropsData {
  websocketData: any;
  dbRecentData: any;
  fontColor: any;
  logo: any;
  unit:any
   backgroundColor?: any;
   pointer:any
}
const Record = (props: PropsData) => {
  return (
    <div className="station">
      <div
        className="boxes p-2 mb-2"
        style={{ backgroundColor: props?.backgroundColor }}
      >
        <div className="d-flex justify-content-start align-items-center">
          <div className="me-2">
            <img src={props?.logo} alt="" sizes="25" className="imgWidth" />
          </div>
          <div>
            <span style={{ color: props?.fontColor }}>
              {props?.websocketData
                ? props?.websocketData
                : props?.dbRecentData}
            </span>
            <small className="small-text" style={{ color: props?.fontColor }}>
              {props?.unit}
            </small>
            <br />
            <span className="cityname" style={{ color: props?.fontColor }}>
              {props?.pointer}
            </span>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
export default Record;
