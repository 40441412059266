import { Routes, Route, RoutesProps, Navigate } from "react-router-dom";

import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import AddKYC from "../components/ManageKYC/Add/AddKYC";
import CommandCentre from "../components/ManageCommandCentre/CommandCentre";
import CommandCentreNewFlow from "../components/ManageCommandCentre/CommandCentreNewFlow";
import CommandCentreNewFlowDetails from "../components/ManageCommandCentre/CommandCentreNewFlowDetails";

let socket = new WebSocket("wss://apiv2.justbreathe.in/live-data");

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));

  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );

  const Profile = React.lazy(
    () => import("../components/Profile/Profile")
  );
  const ResetPassword = React.lazy(
    () => import("../components/Login/SetPassword")
  );
  const KYCQuestion = React.lazy(
    () => import("../components/ManageSite/Add/AddSite")
  );
  const Alert = React.lazy(
    () => import("../components/ManageAlerts/Add/AddAlerts")
  )
  const ManageClients = React.lazy(
    () => import("../components/ManageClients/Add/ViewClient")
  )
  const AddClient = React.lazy(
    () => import("../components/ManageClients/Add/AddClient")
  )
  const AddUser = React.lazy(
    () => import("../components/ManageUser/Add/AddUser")
  )
  const AddStation = React.lazy(
    () => import("../components/ManageStation/Add/AddStation")
  )
  const ManageTabs = React.lazy(
    () => import("../components/ManageTabs/ManageTabs")
  )
  const ViewUser = React.lazy(
    () => import("../components/ManageUser/View/ViewUser")
  )
  const AddFlow = React.lazy(
    () => import("../components/ManageFlow/Add/AddFlow")
  )
  const AddActivityLog = React.lazy(
    () => import("../components/ManageActivityLog/Add/AddActivityLog")
  )

  const StationView = React.lazy(
    () => import("../components/ManageCommandCentre/Station/StationView")
  )
  const StationView1 = React.lazy(
    () => import("../components/ManageCommandCentre/CommandCentreNewFlowDetails")
  )
  const Checklist = React.lazy(
    () => import("../components/ManageChecklist/Checklist")
  )
  const ServiceRequest = React.lazy(
    () => import("../components/ManageServiceRequest/View/ViewServiceRequest")
  )
  const Flow = React.lazy(
    () => import("../components/ManageFlow/Add/Flow")
  )
  const ViewKYC = React.lazy(
    () => import("../components/ManageKYC/Add/AddKYC")
  )
  const QuestionList = React.lazy(
    () => import("../components/ManageKYC/Add/QuestionList")
  )
  const AddSensor = React.lazy(
    () => import("../components/ManageSensors/AddSensors/AddSensor")
  )
  const ViewSensor = React.lazy(
    () => import("../components/ManageSensors/ViewSensors/ViewSensor")
  )
  const OtpVerification = React.lazy(
    () => import("../components/Login/OtpVerification")
  )
  const AdminSetPassword = React.lazy(
    () => import("../components/Login/AdminSetPassword")
  )
  const ViewParentComponent = React.lazy(
    () => import("../components/ManageFlow/ParentComponent/ViewParentComponent")
  )
  const AccessDenied = React.lazy(
    () => import("../components/AccessDenied/AccessDenied")
  )
  const Standard = React.lazy(
    () => import("../components/ManageStandard/List/View")
  )
  const FlowLog = React.lazy(
    () => import("../components/ManageFlowLog/List/View")
  )
  const Delete = React.lazy(
    () => import("../components/Login/Delete")
  )
  const Banner = React.lazy(
    () => import("../components/ManageBanner/Banner")
  )
  const TermsConditions = React.lazy(
    () => import("../components/TermsConditions/TermsConditions")
  );
  const PrivacyPolicy = React.lazy(
    () => import("../components/PrivacyPolicy/PrivacyPolicy")
  );
  const Disclaimer = React.lazy(
    () => import("../components/Disclaimer/Disclaimer")
  );

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ResetPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/question-list/:uuid"
            element={
              <Suspense fallback={<></>}>
                <QuestionList />
              </Suspense>
            }
          />
          <Route
            path="/set-password/:token"
            element={
              <Suspense fallback={<></>}>
                <AdminSetPassword />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/otp-verification"
            element={
              <Suspense fallback={<></>}>
                <OtpVerification />
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<></>}>
                <AdminSetPassword />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >

            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Dashboard />{" "}
                </Suspense>
              }
            />

            <Route
              path="/profile"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Profile />{" "}
                </Suspense>
              }
            />
            <Route
              path="/kyc-questions"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <KYCQuestion />{" "}
                </Suspense>
              }
            />
            <Route
              path="/checklist"
              element={
                <Suspense fallback={<></>}>
                  <Checklist />
                </Suspense>
              }
            />
            <Route
              path="/alerts/template"
              element={
                <Suspense fallback={<></>}>
                  <Alert />
                </Suspense>
              }
            />
            <Route
              path="/manage-clients"
              element={
                <Suspense fallback={<></>}>
                  <ManageClients />
                </Suspense>
              }
            />
            <Route
              path="add-clients"
              element={
                <Suspense fallback={<></>}>
                  <AddClient />
                </Suspense>
              }
            />
            <Route
              path="/add-user"
              element={
                <Suspense fallback={<></>}>
                  <AddUser />
                </Suspense>
              }
            />
            <Route
              path="/stations-add"
              element={
                <Suspense fallback={<></>}>
                  <AddStation />
                </Suspense>
              }
            />
            <Route
              path="/manage-sensors"
              element={
                <Suspense fallback={<></>}>
                  <AddSensor />
                </Suspense>
              }
            />
            <Route
              path="/view-sensors"
              element={
                <Suspense fallback={<></>}>
                  <ViewSensor />
                </Suspense>
              }
            />
            <Route
              path="/tabs"
              element={
                <Suspense fallback={<></>}>
                  <ManageTabs />
                </Suspense>
              }
            />
            <Route
              path="view-user"
              element={
                <Suspense fallback={<></>}>
                  <ViewUser />
                </Suspense>
              }
            />
            <Route
              path="add-kyc"
              element={
                <Suspense fallback={<></>}>
                  <AddKYC />
                </Suspense>
              }
            />
            <Route
              path="/add-flow"
              element={
                <Suspense fallback={<></>}>
                  <AddFlow />
                </Suspense>
              }
            />
            <Route
              path="/activity-add"
              element={
                <Suspense fallback={<></>}>
                  <AddActivityLog />
                </Suspense>
              }
            />
            {/* <Route
              path="/command-centre"
              element={
                <Suspense fallback={<></>}>
                  <CommandCentre />
                </Suspense>
              }
            /> */}
            <Route
              path="/command-centre"
              element={
                <Suspense fallback={<></>}>
                  <CommandCentreNewFlow socket={socket} />
                </Suspense>
              }
            />
            <Route
              path="/command-centre-details/:machineId/:companyId/:outdoorSensorId/:siteId"
              element={
                <Suspense fallback={<></>}>
                  <CommandCentreNewFlowDetails socket={socket} />
                </Suspense>
              }
            />
            <Route
              path="/station-view/:companyId/:machineId"
              element={
                <Suspense fallback={<></>}>
                  <StationView />
                </Suspense>
              }
            />

            <Route
              path="/station-view_1/:companyId/:machineId"
              element={
                <Suspense fallback={<></>}>
                  <StationView1 />
                </Suspense>
              }
            />
            <Route
              path="/service-management"
              element={
                <Suspense fallback={<></>}>
                  <ServiceRequest />
                </Suspense>
              }
            />
            <Route
              path="/flow/:id"
              element={
                <Suspense fallback={<></>}>
                  <Flow />
                </Suspense>
              }
            />
            <Route
              path="/kyc"
              element={
                <Suspense fallback={<></>}>
                  <ViewKYC />
                </Suspense>}
            />
            <Route
              path="/parent-component"
              element={
                <Suspense fallback={<></>}>
                  <ViewParentComponent />
                </Suspense>
              }
            />
            <Route
              path="/banner"
              element={
                <Suspense fallback={<></>}>
                  <Banner />
                </Suspense>
              }
            />
            <Route
              path="/access-denied"
              element={
                <Suspense fallback={<></>}>
                  <AccessDenied />
                </Suspense>
              }
            />
            <Route
              path="/standard"
              element={
                <Suspense fallback={<></>}>
                  <Standard />
                </Suspense>
              }
            />
            <Route
              path="/flow-log"
              element={
                <Suspense fallback={<></>}>
                  <FlowLog />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/delete-account"
            element={
              <Suspense fallback={<></>}>
                <Delete />
              </Suspense>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <TermsConditions />{" "}
              </Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <PrivacyPolicy />{" "}
              </Suspense>
            }
          />
          <Route
            path="/disclaimer"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Disclaimer />{" "}
              </Suspense>
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
