import axios from "axios";
import { toast } from "react-toastify";
//live

//UAT
//  const BASE_URL = "https://stagapiv2.justbreathe.in/"; // process.env.REACT_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_BASE_URL;

interface PropData {
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  key?: string;
}

const WebService = {
  postAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  beforeLoginAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (props.isShowError) {
            reject(this.errorHandler(error))
          } else {
            toast.error(error.response.data.message);
            reject(error)
          };
        });
    });
  },
  beforeLoginPutAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .put(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          toast.error("Invalid details");
        });
    });
  },

  uploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },
  putAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      };
      axios
        .put(`${BASE_URL}${props.action}`, props.body, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  getAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      };
      axios
        .get(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  deleteAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      };
      axios
        .delete(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            props.isShowError
              ? reject(this.errorHandler(error))
              : reject(error);
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    let errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage = "Unauthorized";
      this.logout();
    } else if (error.status === 404) {
      errorMessage = "Server Not Responding";
      this.logout();
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else {
      errorMessage = error.data.message;
    }
    toast.error(errorMessage);
    return errorMessage;
  },

  addLoader(id?: any, status?: any) {
    if (id) {
      let button = document.getElementById(id) as HTMLButtonElement | null;
      if (button) {
        button.disabled = true;
        let loader = document.createElement("span");
        loader.innerHTML =
          '<span style="margin-right:5px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span className="visually-hidden"></span>';
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      let button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },

  logout() {
    localStorage.clear();
    window.location.href = "/login";

  },
};

export default WebService;
