import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRedoAlt, FaSyncAlt } from "react-icons/fa";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Fridgesimg from "../../assets/images/fridgesimg.png";
import Air from "../../assets/images/icons/air.svg";
import Ammonia from "../../assets/images/icons/ammonia.svg";
import Carbon from "../../assets/images/icons/carbon.svg";
import Footprint from "../../assets/images/icons/footprint.svg";
import Humidity from "../../assets/images/icons/humidity.svg";
import Lungs from "../../assets/images/icons/lungs.svg";
import Molecule from "../../assets/images/icons/molecule.svg";
import Nitrogen from "../../assets/images/icons/nitrogen.svg";
import Ozone from "../../assets/images/icons/ozone.svg";
import Temprature from "../../assets/images/icons/temprature.svg";
import liveImage from "../../assets/images/liveimage.svg";
import OzoneLayero3 from "../../assets/images/ozone-layer-o3.svg";
import Sun from "../../assets/images/sun.svg";
import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import FullScreenChart from "../FullScreenChart";
import PageTitle from "../PageTitle";
import Meter from "./meter";
import Record from "./Record";
import { PiTrash } from "react-icons/pi";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface WeatherData {
  temperature: string;
  city: string;
  weatherDiscription?: string;
  icon?: string;
}
type RangeType =
  | "Severe"
  // | "Very Poor"
  | "Poor"
  | "Moderate"
  | "Good"
  | "Excellent"
  | "Satisfactory";
export interface MeterBadge {
  label: string;
  emoji: string;
  badgeClass: string;
  value: number;
  parameter: string;
  count: number;
  noValue: boolean;
}
const CommandCentreNewFlow = (props: any) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
  } = useForm();
  const [webscocketIndoorData, setWebSocketIndoorData] = useState<any>(null);
  const [webSocketOutdoorData, setWebsocketOutdoorData] = useState<any>(null);

  const [indoorData, setIndoorData] = useState<any>(null);
  const [outdoorData, setOutdoorData] = useState<any>(null);
  const [stationData, setStationData] = useState<any>(null);
  const [machineData, setMachineData] = useState<any>(null);

  const [modalShow, setModalShow] = React.useState(false);
  const [isChartView, setIsChartView] = useState(true);
  const [key, setKey] = useState(1);
  const [key1, setKey1] = useState(3);
  const navigate = useNavigate();
  let params = useParams();
  let machineId = params?.machineId;
  let companyId = params?.companyId;
  let outdoorSensorId = params?.outdoorSensorId;
  let siteId = params?.siteId;
  const [stationParameterRangeData, setStationParameterRangeData] =
    useState<any>([]);
  const [indoorSensorStatus, setIndoorSensorStatus] = useState<any>(null);
  const [outdoorSensorStatus, setOutdoorSensorStatus] = useState<any>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [outdoorOptionGraphData, SetOutdoorOptionsGraphData] = useState<any>(
    {}
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDeleteCard, setShowDeletCard] = useState(false);
  const [PM25IndoorObject, setPM25IndoorObject] = useState<MeterBadge>({
    label: "",
    emoji: "",
    badgeClass: "",
    value: 0,
    parameter: "",
    count: 1,
    noValue: true,
  });
  const [PM25OutdoorObject, setPM25OutdoorObject] = useState<MeterBadge>({
    label: "",
    emoji: "",
    badgeClass: "",
    value: 0,
    parameter: "",
    count: 1,
    noValue: true,
  });

  const [weatherData, setWeatherData] = useState<WeatherData | null>(() => {
    const storedData = localStorage.getItem("weatherAPIData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [randomValues, setRandomValues] = useState([
    "co",
    "co2",
    "nh3",
    "no2",
    "o2",
    "o3",
    "pm1",
    "pm2_5",
    "pm10",
    "tvoc",
    "rh",
    "temperature1",
    "temperature2",
    "so2",
    "lux",
  ]);
  const rangeTypeValues: Record<RangeType, string> = {
    Severe: "😢",
    // "Very Poor": "😟",
    Poor: "😞",
    Moderate: "😐",
    Good: "😊",
    Excellent: "😁",
    Satisfactory: "😌",
  };

  const formatOutdoorLabel = (label: any) => {
    switch (label) {
      case "pm2_5":
        return "PM2.5";
      case "pm10":
        return "PM10";
      case "nh3":
        return "NH3";
      case "o2":
        return "O2";
      case "rh":
        return "Relative Humidity";
      case "tvoc":
        return "TVOC";
      case "decibel":
        return "Decibel";
      case "co2":
        return "CO2";
      case "o3":
        return "O3";
      case "co":
        return "CO";
      case "no2":
        return "NO2";
      case "luxlevel":
        return "Lux Level";
      default:
        return label.toUpperCase();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getRecentData(stationParameterRangeData);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const BASE_URL = "https://api.openweathermap.org/";

  const apiKey = "74fc72f9df6e931a1cacc472c29b37b3";

  useEffect(() => {
    if (machineId && companyId && outdoorSensorId) {
      getIndoorData(machineId ?? "");
      getWebsocketView(machineId ?? "", companyId ?? "");
      getStationDetails();
      getRecentDataForIndoorSensor();
      getRecentDataForOutdoorSensor();
    } else {
      window.history.back();
    }
  }, []);
  useEffect(() => {
    getParameterRangeData();
  }, []);

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "MANAGER" || role === "CLIENT") {
      navigate("/access-denied");
    }
  });

  const getWeatherData = (lat: any, long: any) => {
    const lastFetchDate = localStorage.getItem("weatherDataCollectedDate");
    const currentDate = new Date().toISOString().split("T")[0];
    // if (!lastFetchDate || lastFetchDate !== currentDate) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(
        `${BASE_URL}data/2.5/weather?lat=${lat}&lon=${long}&appid=${apiKey}`,
        {
          headers: headers,
        }
      )
      .then((response: any) => {
        let data: WeatherData = {
          temperature: (response?.data?.main?.temp - 273.15).toFixed(2),
          city: response?.data?.name,
          weatherDiscription: response?.data?.weather[0]?.description,
          icon: HelperService.getIconForWeather(
            response?.data?.weather[0]?.icon
          ),
        };
        localStorage.setItem("weatherDataCollectedDate", currentDate);
        localStorage.setItem("weatherAPIData", JSON.stringify(data));
        setWeatherData(data);
      })
      .catch((error: any) => {});
    // }
  };

  const getWebsocketView = (machineId: string, companyId: string) => {
    let socket = new WebSocket("wss://apiv2.justbreathe.in/live-data");
    socket.onmessage = function (event: any) {};

    socket.onopen = function () {
      console.log("WebSocket connection opened.");

      // Subscribe to a topic
      socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${companyId}_${machineId}/stationData`,
        })
      );

      // Outdoor
      socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/OD_${outdoorSensorId}/airScopeDataOutdoor`,
        })
      );

      // airscope data
      socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${companyId}_${machineId}/airScopeData`,
        })
      );

      socket.onmessage = (event: any) => {
        let data = JSON.parse(event?.data);

        if (
          data?.topic ===
          `Devices/M2C/ST_${companyId}_${machineId}/airScopeData`
        ) {
          setWebSocketIndoorData(JSON.parse(data?.content));
        }
        if (
          data.topic === `Devices/M2C/OD_${outdoorSensorId}/airScopeDataOutdoor`
        ) {
          setWebsocketOutdoorData(JSON.parse(data?.content));
        }

        if (
          data.topic === `Devices/M2C/ST_${companyId}_${machineId}/stationData`
        ) {
          let parsedData = JSON.parse(data?.content);
          setMachineData(parsedData);
          console.log(parsedData, "parsedData");
        }

        socket.onclose = function () {
          console.log("WebSocket connection closed.");
        };
      };
    };
  };

  const getIndoorData = (stationId: string) => {
    setLoader(true);
    return WebService.getAPI({
      action: `iot/dashboard?stationId=${stationId}`,
      body: null,
    })
      .then((res: any) => {
        setIndoorData(res?.indoor);
        setOutdoorData(res?.outdoor);

        const keys = Object.keys(res?.indoor?.arrAQI7Days || {})
          .map((key) => moment(key).format("DD-MM-YYYY"))
          .sort((a, b) =>
            moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"))
          );

        //  getGraphdata("PM2.5",res?.indoor,res?.outdoor)
        const valuesIndoor = Object.values(res?.indoor?.arrAQI7Days || {});
        const valuesOutdoor = Object.values(res?.outdoor?.arrAQI7Days || {});

        SetOutdoorOptionsGraphData({
          grid: { top: 20, right: 30, bottom: 40, left: 50 },
          xAxis: {
            type: "category",
            data: keys,
            axisLabel: {
              rotate: 45,
              formatter: (value: any) => value.split(" ")[0],
              fontSize: 8,
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
                fontSize: 10,
              },
              axisLine: {
                lineStyle: {
                  color: "#5470C6",
                },
              },
            },
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  color: "#91CC75",
                },
              },
              position: "right", // Position the second y-axis on the right
              offset: 30, // Offset to avoid overlap with the first y-axis
            },
          ],
          series: [
            {
              name: "Indoor",
              data: valuesIndoor,
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              itemStyle: {
                color: "#32CD32",
              },
              animationDuration: 2000,
              animationEasing: "cubicOut",
              yAxisIndex: 0,
            },
            {
              name: "Outdoor",
              data: valuesOutdoor,
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              itemStyle: {
                color: "#FFA500",
              },
              animationDuration: 2000, // Animation duration in milliseconds
              animationEasing: "cubicOut", // Animation easing function
              yAxisIndex: 1, // Link to the second y-axis
            },
          ],
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(50, 50, 50, 0.7)", // Customize tooltip background
            borderColor: "#333", // Customize tooltip border color
            borderWidth: 1, // Customize tooltip border width
            textStyle: {
              color: "#fff", // Customize tooltip text color
            },
          },
        });
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("error", error);
        toast.error(error?.response?.data?.message);
        setLoader(false);
        return null;
      });
  };

  const getStationDetails = () => {
    setLoader(true);
    WebService.getAPI({
      action: `command-centre/station/detail?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        setLoader(false);
        setStationData(res.result);
        getWeatherData(res.result.latitude, res.result.longitude);
        setValue("outdoorSensorId", res.result.outdoorSensorId);
      })
      .catch((error: any) => {
        setLoader(false);
        return false;
      });
  };

  const getRecentDataForOutdoorSensor = () => {
    setLoader(true);
    WebService.getAPI({
      action: `command-centre/outdoor-sensor/status?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        setLoader(false);
        setOutdoorSensorStatus(res.result);
      })
      .catch((error: any) => {
        setLoader(false);
        return false;
      });
  };

  const getRecentDataForIndoorSensor = () => {
    setLoader(true);
    WebService.getAPI({
      action: `command-centre/indoor-sensor/status?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        setLoader(false);
        setIndoorSensorStatus(res.result);
      })
      .catch((error: any) => {
        setLoader(false);
        return false;
      });
  };

  const handleRefreshData = () => {
    WebService.getAPI({
      action: `command-centre/station/refresh?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch((error: any) => {
        return false;
      });
  };

  const handleIndoorRestartData = () => {
    WebService.getAPI({
      action: `command-centre/station/restart?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch((error: any) => {
        return false;
      });
  };

  const handleOutdoorRestartData = () => {
    WebService.getAPI({
      action: `command-centre/station/sensor/restart?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch((error: any) => {
        return false;
      });
  };

  let temperature1 = webscocketIndoorData?.temperature1 ?? 0;
  let temperature2 = webscocketIndoorData?.temperature2 ?? 0;
  let lowestTemperature = Math.min(temperature1, temperature2);

  let outdoorTemperature1 = webSocketOutdoorData?.temperature1 ?? 0;
  let outdoorTemperature2 = webSocketOutdoorData?.temperature2 ?? 0;
  let outdoorLowestTemperature = Math.min(
    outdoorTemperature1,
    outdoorTemperature2
  );

  const getGaugeChartPercent = (value: any, range: any) => {
    if (value > range?.toRange) {
      return 1;
    }
    return value / 100;
  };

  const getGaugeChartColor = (value: any, range: any) => {
    if (value > range?.toRange) {
      return "red";
    }
    return value > range.fromRange ? "orange" : "green";
  };

  const getParameterRangeData = () => {
    const body = {
      site_id: siteId ?? "",
    };
    var queryParam = new URLSearchParams(body);
    return WebService.getAPI({
      action: `parameter-range?` + queryParam,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setStationParameterRangeData(res.list);
        getRecentData(res?.list);
      })
      .catch((error: any) => {
        return error;
      });
  };
  const getRecentData = (localParameterList: any) => {
    return WebService.getAPI({
      action: `station/most-recent-data?station_id=${machineId}`,
      body: null,
    })
      .then((res: any) => {
        getGaugeChartObject(
          res?.result?.indoorSensorData,
          res?.result?.outdoorSensorData,
          localParameterList
        );
        // setParameterList(res?.list);
        // getRecentData();
      })
      .catch((error: any) => {
        console.log("error", error);
        toast.error(error?.response?.data?.message);
        return null;
      });
  };
  const getGaugeChartObject = (
    recentIndoorData: any,
    recentOutdoorData: any,
    parameterListLocal: any[]
  ) => {
    let parameter =
      randomValues[Math.floor(Math.random() * randomValues.length)];
    let indoorValue = recentIndoorData[parameter];
    let outdoorValue = recentOutdoorData[parameter];

    if (parameterListLocal && parameterListLocal.length > 0) {
      const count = parameterListLocal.filter(
        (record) =>
          record.parameterName.toUpperCase() === parameter.toUpperCase()
      ).length;

      let matched = false;
      let matched1 = false;

      for (let item of parameterListLocal) {
        const {
          parameterName,
          fromRange,
          toRange,
          rangeType,
          rangeColorInHex,
        } = item;

        if (
          parameterName.toUpperCase() === parameter.toUpperCase() &&
          indoorValue >= fromRange &&
          indoorValue <= toRange
        ) {
          matched = true;
          if (parameter === "temperature1" || parameter === "temperature2") {
            const temp1 = recentIndoorData["temperature1"];
            const temp2 = recentIndoorData["temperature2"];

            if (temp1 < temp2) {
              parameter = "temperature";
              indoorValue = temp1;
            } else {
              parameter = "temperature";
              indoorValue = temp2;
            }
          }
          setPM25IndoorObject({
            label: rangeType ?? "",
            emoji: rangeTypeValues[rangeType as RangeType],
            badgeClass: rangeColorInHex,
            parameter: parameter ?? "",
            value: indoorValue ?? 0,
            count: count,
            noValue: false,
          });
          setKey(Math.random());
          break;
        }
      }
      for (let item of parameterListLocal) {
        const {
          parameterName,
          fromRange,
          toRange,
          rangeType,
          rangeColorInHex,
        } = item;

        if (
          parameterName.toUpperCase() === parameter.toUpperCase() &&
          outdoorData >= fromRange &&
          outdoorData <= toRange
        ) {
          matched1 = true;

          if (parameter === "temperature1" || parameter === "temperature2") {
            const temp1 = recentOutdoorData["temperature1"];
            const temp2 = recentOutdoorData["temperature2"];

            if (temp1 < temp2) {
              parameter = "temperature";
              outdoorValue = temp1;
            } else {
              parameter = "temperature";
              outdoorValue = temp2;
            }
          }
          setPM25OutdoorObject({
            label: rangeType ?? "",
            emoji: rangeTypeValues[rangeType as RangeType],
            badgeClass: rangeColorInHex,
            parameter: parameter ?? "",
            value: outdoorValue ?? 0,
            count: count,
            noValue: false,
          });
          setKey1(Math.random());
          break;
        }
      }

      if (!matched) {
        setPM25IndoorObject({
          label: "Poor",
          emoji: rangeTypeValues["Poor"],
          badgeClass: "#cc3232",
          parameter: parameter ?? "",
          value: indoorValue ?? 0,
          noValue: true,
          count: 1,
        });
        setKey(Math.random());
      }

      if (!matched1) {
        setPM25OutdoorObject({
          label: "Poor",
          emoji: rangeTypeValues["Poor"],
          badgeClass: "#cc3232",
          parameter: parameter ?? "",
          value: outdoorValue ?? 0,
          noValue: true,
          count: 1,
        });
        setKey1(Math.random());
      }
    }
  };
  const getGraphdata = (
    value: any,
    indoorDataLocal: any,
    outDoorDatalocal: any
  ) => {
    let valuesIndoor = {};
    let valuesOutdoor = {};
    let keys = {};
    switch (value) {
      case "PM2.5":
        valuesIndoor = Object.values(indoorDataLocal?.arrPm25 || {});
        valuesOutdoor = Object.values(outDoorDatalocal?.arrPm25 || {});
        keys = Object.keys(indoorDataLocal?.arrPm25 || {})
          .map((key) => moment(key).format("DD-MM-YYYY"))
          .sort((a, b) =>
            moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"))
          );
        setValue("parameter", value);
        break;
      // case "PM1":
      //   valuesIndoor = Object.values(indoorDataLocal?.arrPm1 || {});
      //   valuesOutdoor = Object.values(outDoorDatalocal?.arrPm1 || {});
      //   keys = Object.keys(indoorData?.arrPm1 || {})
      //     .map((key) => moment(key).format("DD-MM-YYYY"))
      //     .sort((a, b) =>
      //       moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"))
      //     );
      //   setValue("parameter", value);
      //   break;
      default:
        return;
    }

    SetOutdoorOptionsGraphData({
      grid: { top: 20, right: 30, bottom: 40, left: 50 },
      xAxis: {
        type: "category",
        data: keys,
        axisLabel: {
          rotate: 45,
          formatter: (value: any) => value.split(" ")[0],
          fontSize: 8,
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: "{value}",
            fontSize: 10,
          },
          axisLine: {
            lineStyle: {
              color: "#5470C6",
            },
          },
        },
        {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          axisLine: {
            lineStyle: {
              color: "#91CC75",
            },
          },
          position: "right", // Position the second y-axis on the right
          offset: 30, // Offset to avoid overlap with the first y-axis
        },
      ],
      series: [
        {
          name: "Indoor",
          data: valuesIndoor,
          type: "line",
          smooth: true,
          lineStyle: {
            width: 1,
          },
          itemStyle: {
            color: "#32CD32",
          },
          animationDuration: 2000,
          animationEasing: "cubicOut",
          yAxisIndex: 0,
        },
        {
          name: "Outdoor",
          data: valuesOutdoor,
          type: "line",
          smooth: true,
          lineStyle: {
            width: 1,
          },
          itemStyle: {
            color: "#FFA500",
          },
          animationDuration: 2000, // Animation duration in milliseconds
          animationEasing: "cubicOut", // Animation easing function
          yAxisIndex: 1, // Link to the second y-axis
        },
      ],
      tooltip: {
        trigger: "axis",
        backgroundColor: "rgba(50, 50, 50, 0.7)", // Customize tooltip background
        borderColor: "#333", // Customize tooltip border color
        borderWidth: 1, // Customize tooltip border width
        textStyle: {
          color: "#fff", // Customize tooltip text color
        },
      },
    });
  };
  const getColorCode = (
    value: number,
    parameter: string,
    parameterListLocal: any[]
  ) => {
    if (parameterListLocal && parameterListLocal.length > 0) {
      for (let item of parameterListLocal) {
        const { parameterName, fromRange, toRange, rangeColorInHex } = item;

        if (
          parameterName.toUpperCase() === parameter.toUpperCase() &&
          value >= fromRange &&
          value <= toRange
        ) {
          return rangeColorInHex;
        }
      }
      // changeGraphColor(parameter.toUpperCase(), "#cc3232");
      return "#cc3232";
    } else {
      // changeGraphColor(parameter.toUpperCase(), "#cc3232");
      return "#cc3232";
    }
  };

  const handleDeleteCommandCentreData = () => {
    let deleteStartDate = moment(startDate).format("DD-MM-YYYY HH:mm");
    let deleteEndDate = moment(endDate).format("DD-MM-YYYY HH:mm");
    WebService.addLoader("deleteButton");
    WebService.deleteAPI({
      action: `command-centre/delete-data?start_date=${deleteStartDate}&end_date=${deleteEndDate}&station_id=${machineId}&appLang`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        WebService.removeLoader("deleteButton");
        toast.success(res.message);
        setShowDeletCard(false);
      })
      .catch((error: any) => {
        WebService.removeLoader("deleteButton");
        return false;
      });
  };

  return (
    <>
      <Row>
        <Col>
          <div
            className="justify-content-between align items-center mb-3"
            style={{ display: "flex" }}
          >
            <PageTitle Title={t("Station_Details")} />
            <div>
              <FaSyncAlt
                style={{
                  height: "30px",
                  width: "30px",
                  cursor: "pointer",
                  marginRight: "30px",
                }}
                title="Refresh Page"
                onClick={() => handleRefreshData()}
              />

              <PiTrash
                style={{ height: "35px", width: "35px", cursor: "pointer" }}
                className="icon"
                title="Delete Record"
                onClick={() => setShowDeletCard(true)}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Modal show={showDeleteCard} onHide={() => setShowDeletCard(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>
            <b>Delete Data</b>
          </h4>
          <p>Please select the period for which you want to delete</p>
          <div className="d-flex gap-2">
            <div className="form-group">
              <DatePicker
                selected={startDate}
                dateFormat={"dd/MM/yyyy hh:mm a"}
                showTimeSelect
                onChange={(date: any) => setStartDate(date)}
              />
            </div>
            <div className="d-flex align-items-center">To</div>
            <div className="">
              <DatePicker
                dateFormat={"dd/MM/yyyy hh:mm a"}
                showTimeSelect
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
              />
            </div>
          </div>
          <br></br>{" "}
          <Button
            variant="primary"
            onClick={() => {
              setEndDate(new Date());
              setStartDate(new Date());
              handleDeleteCommandCentreData();
            }}
            className="btn-lg px-4"
            id="deleteButton"
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>

      <div className="commandCentre-page mb-4">
        <Row className="g-3">
          <Col lg={5} sm={5}>
            <Row className="g-3">
              <Col lg={6} sm={6}>
                <div className="white-card-box position-relative">
                  <div className="mb-2">
                    <strong>Indoor Data</strong>
                    &nbsp; <img src={liveImage} width="38" alt="Logo" />
                    &nbsp;
                    <FaRedoAlt
                      style={{
                        height: "18px",
                        width: "18px",
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      title="Restart"
                      onClick={handleIndoorRestartData}
                    />
                  </div>
                  <Row className="g-2">
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Lungs}
                        websocketData={webscocketIndoorData?.pm1}
                        dbRecentData={indoorData?.livePm1}
                        unit="µg/m³"
                        fontColor={getColorCode(
                          webscocketIndoorData?.pm1
                            ? webscocketIndoorData?.pm1
                            : indoorData?.livePm1,
                          "PM1",
                          stationParameterRangeData
                        )}
                        pointer="PM 1"
                        backgroundColor={
                          indoorSensorStatus?.asdpms == 0 ||
                          indoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Lungs}
                        websocketData={webscocketIndoorData?.pm2_5}
                        dbRecentData={indoorData?.livePm25}
                        unit="μg/m³"
                        pointer="PM 2.5"
                        fontColor={getColorCode(
                          webscocketIndoorData?.pm2_5
                            ? webscocketIndoorData?.pm2_5
                            : indoorData?.livePm25,
                          "PM25",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdpms == 0 ||
                          indoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Air}
                        websocketData={webscocketIndoorData?.pm10}
                        dbRecentData={indoorData?.livePm10}
                        unit="μg/m³"
                        pointer="PM 10"
                        fontColor={getColorCode(
                          webscocketIndoorData?.pm10
                            ? webscocketIndoorData?.pm10
                            : indoorData?.livePm10,
                          "PM10",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdpms == 0 ||
                          indoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Molecule}
                        websocketData={webscocketIndoorData?.tvoc}
                        dbRecentData={indoorData?.liveTvoc}
                        unit="μg/m³"
                        pointer="TVOC"
                        fontColor={getColorCode(
                          webscocketIndoorData?.tvoc
                            ? webscocketIndoorData?.tvoc
                            : indoorData?.liveTvoc,
                          "TVOC",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdtvoc == 0 ||
                          indoorSensorStatus?.asdtvoc == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Footprint}
                        websocketData={webscocketIndoorData?.co2}
                        dbRecentData={indoorData?.liveSo2}
                        unit="μg/m³"
                        pointer="CO2"
                        fontColor={getColorCode(
                          webscocketIndoorData?.co2
                            ? webscocketIndoorData?.co2
                            : indoorData?.liveSo2,
                          "CO2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdco2 == 0 ||
                          indoorSensorStatus?.asdco2 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Temprature}
                        websocketData={webscocketIndoorData?.temperature1}
                        dbRecentData={indoorData?.liveTemperature}
                        unit="C"
                        pointer="Temp"
                        fontColor={getColorCode(
                          webscocketIndoorData?.temperature1
                            ? webscocketIndoorData?.temperature1
                            : indoorData?.liveTemperature,
                          "TEMPERATURE1",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdowt == 0 ||
                          indoorSensorStatus?.asdowt == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Humidity}
                        websocketData={webscocketIndoorData?.humidity}
                        dbRecentData={indoorData?.liveRh}
                        unit="%"
                        pointer="RH"
                        fontColor={getColorCode(
                          webscocketIndoorData?.humidity
                            ? webscocketIndoorData?.humidity
                            : indoorData?.liveRh,
                          "humidity",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdpms == 0 ||
                          indoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Footprint}
                        websocketData={webscocketIndoorData?.o2}
                        dbRecentData={indoorData?.liveO3}
                        unit="ppm"
                        pointer="O2"
                        fontColor={getColorCode(
                          webscocketIndoorData?.o2
                            ? webscocketIndoorData?.o2
                            : indoorData?.liveo3,
                          "o2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdo2 == 0 ||
                          indoorSensorStatus?.asdo2 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        logo={Ozone}
                        websocketData={webscocketIndoorData?.o3}
                        dbRecentData={indoorData?.liveO3}
                        unit="µg/m³"
                        pointer="O3"
                        fontColor={getColorCode(
                          webscocketIndoorData?.o3
                            ? webscocketIndoorData?.o3
                            : indoorData?.liveo3,
                          "o2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdo3 == 0 ||
                          indoorSensorStatus?.asdo3 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="µg/m³"
                        pointer="CO"
                        logo={Carbon}
                        websocketData={webscocketIndoorData?.co}
                        dbRecentData={indoorData?.liveCo}
                        fontColor={getColorCode(
                          webscocketIndoorData?.co
                            ? webscocketIndoorData?.co
                            : indoorData?.liveCo,
                          "co",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdmics == 0 ||
                          indoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="NO2"
                        logo={Nitrogen}
                        websocketData={webscocketIndoorData?.no2}
                        dbRecentData={indoorData?.liveSo2}
                        fontColor={getColorCode(
                          webscocketIndoorData?.no2
                            ? webscocketIndoorData?.no2
                            : indoorData?.liveSo2,
                          "NO2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdmics == 0 ||
                          indoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="NH3"
                        logo={Ammonia}
                        websocketData={webscocketIndoorData?.nh3}
                        dbRecentData={indoorData?.liveNh3}
                        fontColor={getColorCode(
                          webscocketIndoorData?.nh3
                            ? webscocketIndoorData?.nh3
                            : indoorData?.liveNh3,
                          "NH3",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          indoorSensorStatus?.asdmics == 0 ||
                          indoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} sm={6}>
                <div className="white-card-box position-relative">
                  <div className="mb-2">
                    <strong>Outdoor Data</strong>
                    &nbsp; <img src={liveImage} width="38" alt="Logo" />
                    <FaRedoAlt
                      style={{
                        height: "18px",
                        width: "18px",
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                      }}
                      title="Restart"
                      onClick={handleOutdoorRestartData}
                    />
                  </div>
                  <Row className="g-2">
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="µg/m³"
                        pointer="PM 1"
                        logo={Lungs}
                        websocketData={webSocketOutdoorData?.pm1}
                        dbRecentData={outdoorData?.livePm1}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.pm1
                            ? webSocketOutdoorData?.pm1
                            : outdoorData?.livePm1,
                          "PM1",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdpms == 0 ||
                          outdoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="PM 2.5"
                        logo={Lungs}
                        websocketData={webSocketOutdoorData?.pm2_5}
                        dbRecentData={outdoorData?.livePm25}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.pm2_5
                            ? webSocketOutdoorData?.pm2_5
                            : outdoorData?.livePm25,
                          "PM25",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdpms == 0 ||
                          outdoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="PM 10"
                        logo={Air}
                        websocketData={webSocketOutdoorData?.pm10}
                        dbRecentData={outdoorData?.livePm10}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.pm10
                            ? webSocketOutdoorData?.pm10
                            : outdoorData?.livePm10,
                          "PM10",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdpms == 0 ||
                          outdoorSensorStatus?.asdpms == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="µg/m³"
                        pointer="TVOC"
                        logo={Molecule}
                        websocketData={webSocketOutdoorData?.tvoc}
                        dbRecentData={outdoorData?.liveTvoc}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.tvoc
                            ? webSocketOutdoorData?.tvoc
                            : outdoorData?.liveTvoc,
                          "TVOC",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdtvoc == 0 ||
                          outdoorSensorStatus?.asdtvoc == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="ppm"
                        pointer="CO2"
                        logo={Footprint}
                        websocketData={webSocketOutdoorData?.co2}
                        dbRecentData={outdoorData?.liveSo2}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.co2
                            ? webSocketOutdoorData?.co2
                            : outdoorData?.liveSo2,
                          "CO2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdco2 == 0 ||
                          outdoorSensorStatus?.asdco2 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="C"
                        pointer="Temp"
                        logo={Temprature}
                        websocketData={webSocketOutdoorData?.temperature1}
                        dbRecentData={outdoorData?.liveTemperature}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.temperature1
                            ? webSocketOutdoorData?.temperature1
                            : outdoorData?.liveTemperature,
                          "TEMPERATURE1",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdowt == 0 ||
                          outdoorSensorStatus?.asdowt == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="%"
                        pointer="RH"
                        logo={Humidity}
                        websocketData={webSocketOutdoorData?.humidity}
                        dbRecentData={outdoorData?.liveRh}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.humidity
                            ? webSocketOutdoorData?.humidity
                            : outdoorData?.liveRh,
                          "humidity",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asddht == 0 ||
                          outdoorSensorStatus?.asddht == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="ppm"
                        pointer="O2"
                        logo={Footprint}
                        websocketData={webSocketOutdoorData?.o2}
                        dbRecentData={outdoorData?.liveCo}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.o2
                            ? webSocketOutdoorData?.o2
                            : outdoorData?.liveCo,
                          "o2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdo2 == 0 ||
                          outdoorSensorStatus?.asdo2 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="µg/m³"
                        pointer="O3"
                        logo={Ozone}
                        websocketData={webSocketOutdoorData?.o3}
                        dbRecentData={outdoorData?.liveO3}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.o3
                            ? webSocketOutdoorData?.o3
                            : outdoorData?.liveO3,
                          "O3",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdo3 == 0 ||
                          outdoorSensorStatus?.asdo3 == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="µg/m³"
                        pointer="CO"
                        logo={Carbon}
                        websocketData={webSocketOutdoorData?.co}
                        dbRecentData={outdoorData?.liveCo}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.co
                            ? webSocketOutdoorData?.co
                            : outdoorData?.liveCo,
                          "CO",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdmics == 0 ||
                          outdoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="NO2"
                        logo={Nitrogen}
                        websocketData={webSocketOutdoorData?.no2}
                        dbRecentData={outdoorData?.liveNo2}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.no2
                            ? webSocketOutdoorData?.no2
                            : outdoorData?.liveNo2,
                          "NO2",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdmics == 0 ||
                          outdoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <Record
                        unit="μg/m³"
                        pointer="NH3"
                        logo={Ammonia}
                        websocketData={webSocketOutdoorData?.nh3}
                        dbRecentData={outdoorData?.liveNh3}
                        fontColor={getColorCode(
                          webSocketOutdoorData?.nh3
                            ? webSocketOutdoorData?.nh3
                            : outdoorData?.liveNh3,
                          "NH3",
                          stationParameterRangeData
                        )}
                        backgroundColor={
                          outdoorSensorStatus?.asdmics == 0 ||
                          outdoorSensorStatus?.asdmics == -1
                            ? "#efddde"
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={7} sm={7}>
            <Row className="g-3">
              <Col lg={6} sm={6}>
                <div className="white-card-box" style={{ height: "235px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="h5-title">
                        Recent data from Machine{" "}
                        <img src={liveImage} width="38" alt="Logo" />
                      </h5>
                    </div>
                    <div className="dates ms-auto">
                      {machineData && machineData?.dateTime ? (
                        <>
                          {" "}
                          <span className="text-secondary font-bold">
                            
                            {moment(machineData?.dateTime).isValid() ?
                              `Date & Time : ${moment(machineData?.dateTime).format(
                                "DD-MM-YYYY HH:mm:ss A"
                              )}`:""}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="brderBtm"></div>
                  <Row className="g-3 mb-2">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Moisture Lvl.</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.moistureLevel
                          ? machineData?.moistureLevel
                          : 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Water Lvl.</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.waterLevel ? machineData?.waterLevel : 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM1</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.pm1 ? machineData?.pm1 : 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM2.5</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.pm2_5 ? machineData?.pm2_5 : 0}
                      </span>
                    </Col>
                  </Row>
                  <Row className="g-3 mb-2">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM10</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.pm10 ? machineData?.pm10 : 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Temp.</span>{" "}
                      {/* Temperature */}
                      <br />
                      <span className="sub-titles">
                        {machineData?.temperature?.toFixed(2) || 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Humidity</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.humidity?.toFixed(2) || 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Lux</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.lux?.toFixed(2) || 0}
                      </span>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Decibel</span>
                      <br />
                      <span className="sub-titles">{machineData?.db || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Battery Lvl.</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.battery || 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Current</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.amp || 0}
                      </span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Voltage</span>
                      <br />
                      <span className="sub-titles">
                        {machineData?.volt || 0}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} sm={6}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="h5-title">
                        Recent data from Airscope{" "}
                        <img src={liveImage} width="38" alt="Logo" />
                      </h5>
                    </div>
                    <div className="dates ms-auto">
                      {webscocketIndoorData &&
                      webscocketIndoorData?.dateTime ? (
                        <>
                          {" "}
                          <span>
                            Date & Time :
                            {moment(indoorData?.dateTime).format(
                              "DD-MM-YYYY HH:mm:ss A"
                            )}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="brderBtm"></div>
                  <Row className="g-3 mb-2">
                    <Col>
                      <span className="main-titles">PM1</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.pm1 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">PM2.5</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.pm2_5 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">PM10</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.pm10 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">Temp. 1</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.temperature1 || 0}{" "}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">NH3</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.nh3 || 0}
                      </span>
                    </Col>
                  </Row>
                  <Row className="g-3 mb-2">
                    <Col>
                      <span className="main-titles">02</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.o2 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">Temp. 2</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.temperature2 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">Humidity</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.humidity || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">TVOC</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.tvoc || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">Decibel</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.decibel ||
                          webscocketIndoorData?.db ||
                          0}
                      </span>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col>
                      <span className="main-titles">CO2</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.co2 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">03</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.o3 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">C0</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.co || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">NO2</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.no2 || 0}
                      </span>
                    </Col>
                    <Col>
                      <span className="main-titles">Lux</span>
                      <br />
                      <span className="sub-titles">
                        {webscocketIndoorData?.luxlevel ||
                          webscocketIndoorData?.lux ||
                          0}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12} sm={12} className="mt-3">
                <div className="white-card-box" style={{ height: "235px" }}>
                  <Row>
                    <Col lg={3} sm={3}>
                      <div
                        style={{ background: "#F4F4F4" }}
                        onClick={() => {
                          setModalShow(true);
                          setIsChartView(false);
                        }}
                      >
                        <img
                          src={stationData?.stationImage || Fridgesimg}
                          alt=""
                          width={"100%"}
                          style={{
                            padding: "10px",
                            height: "160px",
                            objectFit: "contain",
                            width: "100%",
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={9} sm={9}>
                      <Row className="g-3 mb-2">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Leaf Guardian</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.leafGuardian || "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Water Filled</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.lastWaterFilled
                              ? moment(stationData?.lastWaterFilled).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Station Mode</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.stationMode || "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Location</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.location || "-"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-3 mb-2">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Service Date</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.lastServiceDate
                              ? moment(stationData?.lastServiceDate).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Irrigation</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.lastIrrigationDate
                              ? moment(stationData?.lastIrrigationDate).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Timing</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.timing || "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Tag</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.tag || "-"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-3">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Next Service Date</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.nextServiceDate
                              ? moment(stationData?.nextServiceDate).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </span>
                        </Col>

                        {/* <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Building</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.building || "-"}
                          </span>
                        </Col> */}
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Client Name</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.clientName || "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Station Name</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.stationName || "-"}
                          </span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Machine ID</span>
                          <br />
                          <span className="sub-titles">
                            {stationData?.machineId || "-"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="g-3 mt-0">
          <Col lg={6} sm={6} className="mb-3 ">
            <div className="white-card-box py-3 commandcenterbox">
              <Row>
                <Col lg={12} className="text-center font-bold font-16">
                  <p>
                    {PM25IndoorObject?.parameter
                      ? formatOutdoorLabel(PM25IndoorObject?.parameter)
                      : ""}
                  </p>
                </Col>
                <Col lg={6} sm={6}>
                  <div className="boxshadow p-2 pb-0">
                    <center className=" mb-2">
                      <Meter data={PM25IndoorObject} key={key} />
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="text-center">
                          {" "}
                          <p className="mb-0 ms-4 me-4"></p>
                        </div>
                        <div>
                          {PM25IndoorObject?.label ? (
                            <div
                              style={{
                                background:
                                  PM25IndoorObject?.badgeClass || "#cc3232",
                                borderRadius: "10px",
                                marginTop: "-10px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              className="font-10 font-bold d-flex align-items-center justify-content-center mt-0 col-12 px-0 me-2 mx-auto slowveryfase"
                            >
                              {PM25IndoorObject?.emoji}
                              {PM25IndoorObject?.label}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          {" "}
                          <b className="font-14">(Indoor)</b>
                        </div>
                      </div>
                    </center>
                  </div>
                </Col>
                <Col lg={6} sm={6}>
                  <div className="boxshadow p-2 pb-0 ">
                    <center className="mb-2">
                      {/* <b className="font-16">(Outdoor)</b> */}
                      <Meter data={PM25OutdoorObject} key={key1} />
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "0px" }}
                      >
                        <div>
                          {" "}
                          <p className="mb-0 ms-4 me-4"></p>
                        </div>
                        <div>
                          {PM25OutdoorObject?.label ? (
                            <div
                              style={{
                                background:
                                  PM25OutdoorObject?.badgeClass || "#cc3232",
                                borderRadius: "10px",
                                marginTop: "-12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              className={`font-10 font-bold d-flex align-items-center justify-content-center mt-1 col-12 mx-auto me-3 slowveryfase`}
                            >
                              {PM25OutdoorObject?.emoji}
                              {PM25OutdoorObject?.label}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          {" "}
                          <b className="font-14">(Outdoor)</b>
                        </div>
                      </div>
                    </center>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4} sm={4}>
            <div className="white-card-box py-4 commandcenterbox">
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-2">
                  {/* <select
                    style={{
                      float: "left",
                      width: "100px",
                      marginRight: "10px",
                    }}
                    className="form-select"
                    {...register("parameter")}
                    onChange={(event) => {
                      getGraphdata(event.target.value,indoorData,outdoorData);
                    }}
                  >
                    <option value={"PM2.5"} selected>
                      {"PM2.5"}
                    </option>
                    <option value={"PM1"}>{"PM1"}</option>
                    <option value={"PM10"}>{"PM10"}</option>
                    <option value={"TVOC"}>{"TVOC"}</option>
                    <option value={"CO2"}>{"CO2"}</option>
                    <option value={"TEMP"}>{"TEMP"}</option>
                    <option value={"RH"}>{"RH"}</option>
                    <option value={"O2"}>{"O2"}</option>
                    <option value={"O3"}>{"O3"}</option>
                    <option value={"CO"}>{"CO"}</option>
                    <option value={"NO2"}>{"NO2"}</option>
                    <option value={"NH3"}>{"NH3"}</option>
                  </select> */}
                  <span
                    style={{
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#32CD32",
                      marginRight: "5px",
                    }}
                  ></span>
                  <span className="font-bold font-12">Indoor</span>
                  <br />
                  <span
                    style={{
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#FFA500",
                      marginRight: "5px",
                    }}
                  ></span>
                  <span className="font-bold font-10">Outdoor</span>
                </div>
                <div className="col-8 text-center">
                  <span className="text-text-nowrap font-12 font-bold ">
                    AQI- Indoor Vs. Outdoor
                  </span>
                </div>
                <div
                  className="col-2 text-end"
                  onClick={() => {
                    setModalShow(true);
                    setIsChartView(true);
                  }}
                >
                  <HiMiniViewfinderCircle size={20} />
                </div>
              </div>
              <div className="py-2">
                <FullScreenChart
                  show={modalShow}
                  src={stationData?.stationImage || Fridgesimg}
                  outdoorOptionGraphData={outdoorOptionGraphData}
                  isChartView={isChartView}
                  onHide={() => setModalShow(false)}
                />
                <ReactECharts
                  option={outdoorOptionGraphData}
                  className="react-charts-height"
                  //style={{ height: "110px", width: "100%" }}
                />
              </div>
              <div className="mt-1">
                {/* <span className="text-success font-12 font-bold  mx-5">
                  Live
                </span> */}
              </div>
            </div>
          </Col>
          <Col lg={2} sm={2}>
            <div className="white-card-box py-4 pb-3 commandcenterbox">
              <div
                className="boxshadow1 weatherbg p-3 h-100"
                style={{ height: "200px" }}
              >
                <div className="d-flex align-items-center my-2 h-100 row">
                  <Col lg={7} className="pe-0 d-flex align-items-center">
                    <div className="weather py-2">
                      <h5 className="mb-1 text">
                        {" "}
                        {weatherData && weatherData?.temperature}°C
                      </h5>
                      <p className="mb-0 font-400 font-12 text-dark">
                        Hello, {weatherData && weatherData?.city}!
                      </p>
                    </div>
                  </Col>
                  <Col lg={5} className="text-center">
                    <h5 className="mb-1">
                      <img src={Sun} alt="Logo" width="30" />
                    </h5>
                    <p className="font-12 mb-0 font-400">
                      {weatherData && weatherData?.weatherDiscription}
                    </p>
                  </Col>
                </div>
              </div>
              <div className="brderBtm"></div>
              <div className="d-flex justify-content-between aling-items-center">
                <div className="font-10">
                  <span className="font-bold">
                    Dominant
                    <br />
                    Pollutant
                  </span>{" "}
                  (Indoor)
                </div>
                <div>
                  <img src={OzoneLayero3} alt="" />{" "}
                  <span className="font-12" style={{ color: "#278C5D" }}>
                    ({indoorData && indoorData?.dominantPollutant})
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CommandCentreNewFlow;
