import { Container, Navbar, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/just_breathe_white.svg";
import { SlUser } from "react-icons/sl";
import { GoChevronDown } from "react-icons/go";
import { RiLogoutCircleLine } from "react-icons/ri";
import WebService from "../../Services/WebService";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { updateProfileImage } from "../../Redux/actions";
import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";

const Header = () => {
  const profileImage = useSelector((state: any) => state.profile.profileImage ?? "");
  const dispatch = useDispatch();
  const [image, setImage] = useState("")
  const [notification, setNotification] = useState<any>([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const meCall = () => {
    return WebService.getAPI({
      action: `user/me`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        dispatch(updateProfileImage(res.result.userProfileImage));
        localStorage.setItem("profileImage", res.result.userProfileImage);
        setImage(res.result.userProfileImage)
      })
      .catch((error) => {
        return error;
      });
  };

  const getWebsocketView = () => {
    let socket = new WebSocket("wss://apiv2.justbreathe.in/live-data");

    socket.onopen = function () {
      socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/notifications`,
        })
      );

      socket.onmessage = (event: any) => {
        let data = JSON.parse(event?.data);
        if (data.topic === `Devices/M2C/notifications`) {
          let parseData = JSON.parse(data?.content);
          setNotification((prevNotifications: any) => [
            ...prevNotifications,
            parseData,
          ]);
          if (!parseData.read) {
            setNotificationCount((prevCount: any) => prevCount + 1);
          }
        }

        socket.onclose = function () {
          console.log("WebSocket connection closed.");
        };
      };
    };
  };

  const markAsRead = (index: any) => {
    setNotification((prevNotifications: any) => {
      const updatedNotifications = [...prevNotifications];
      if (!updatedNotifications[index].read) {
        updatedNotifications[index].read = true;
        setNotificationCount((prevCount: any) => prevCount - 1);
      }
      return updatedNotifications;
    });
  };

  const handleNotification = () => {
    WebService.getAPI({
      action: `user/notification?page=1`,
      body: null,
      isShowError: true
    }).then((res: any) => {
      const unreadNotifications = res.list.filter((item: any) => !item.read);
      setNotification((prevNotifications: any) => [
        ...prevNotifications,
        ...res.list,
      ]);
      setNotificationCount((prevCount: any) => prevCount + unreadNotifications.length);
    }).catch((error: any) => {
      return false;
    })
  }

  useEffect(() => {
    meCall();
    handleNotification();
    getWebsocketView();
  }, [image])
  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Brand className="me-5">
              <img src={Logo} width={235} height={47} alt="Just Breathe" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              <Dropdown className="profile-dd">
                <Dropdown.Toggle>
                  <p className="font-20 mb-0 text-dark font-medium">
                    <FaBell />
                    {notificationCount > 0 && (
                      <span className="badge" style={{ color: "green" }}>
                        {notificationCount}
                      </span>
                    )}
                  </p>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '450px', overflowY: 'auto' }}>
                  {notification.length > 0 ? (
                    notification.map((notification: any, index: any) => (
                      <Dropdown.Item
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(index)
                        }}
                        style={{ position: 'relative' }}
                      >
                        <div>
                          { !notification.read && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: 'green',
                              }}
                            />
                          )}
                          <p>
                            {notification.station.machineId}<br />
                            {notification.station.name}<br />
                            {notification.title} <br />
                            {notification.message}
                          </p>
                        </div>
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>No notifications</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="profile-dd">
                <Dropdown.Toggle>
                  <div className="d-flex gap-2">
                    <div>
                      <img
                        src={image}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                        onError={(error: any) => {
                          meCall()
                        }}
                      />
                    </div>
                    <div>
                      <p className="font-14 mb-0 text-dark font-medium">
                        {localStorage.getItem("admin_name")}
                      </p>
                      <p className="font-12 mb-0 text-secondary">
                        {localStorage.getItem("admin_email")}
                      </p>
                    </div>
                  </div>
                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Link to="/Profile" className=" dropdown-item">
                    <SlUser size={14} className="me-2" /> My Profile
                  </Link>
                  <Link
                    to="/login"
                    className=" dropdown-item"
                    onClick={() => {
                      WebService.logout()
                    }}
                  >
                    <RiLogoutCircleLine size={14} className="me-2" /> Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
