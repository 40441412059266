import React from "react";
import GaugeChart from "react-gauge-chart";
interface PropsData {
  data: any;
  key: any;
}
const Meter = (Props: PropsData) => {
  return (
    <>
      <GaugeChart
        animDelay={1}
        animateDuration={1}
        key={Props?.data?.key}
        id="gauge-chart3"
        style={{}}
        nrOfLevels={Props?.data?.count ?? 1}
        colors={["#2dc937", "#99c140", "#e7b416", "#db7b2b", "#cc3232"]}
        arcWidth={0.05}
        formatTextValue={() =>
          Props?.data?.value ? (Props?.data?.value).toString() : "0"
        }
        percent={
          Props?.data?.value
            ? Props?.data?.noValue
              ? 1
              : Math.min(Props?.data?.value / 100, 1)
            : 1
        }
        textColor={"black"}
        // hideText={true}
      />
    </>
  );
};

export default React.memo(Meter);